import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import Layout from '../components/Layout';
import { isAuthenticated } from '../utils/auth';

interface LegacyPageTemplateProps {
  html: string;
}

export const LegacyPageTemplate = ({ html }: LegacyPageTemplateProps) => {
  return (
    <div
      className="page-content"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

const LegacyPage = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const [authenticated, setAuthenticated] = useState(false);
  const { markdownRemark: { frontmatter, html } } = data // data.markdownRemark holds your post data
  const { title, bodyId, passwordProtected } = frontmatter;

  useEffect(() => {
    if(passwordProtected && !isAuthenticated()) {
      navigate('/login/?r=' + encodeURI( window.location.href));

      return undefined;
    } else {
      setAuthenticated(true);
    }

    return undefined;
  }, [passwordProtected])

  return (
    <Layout pageTitle={title} bodyId={bodyId}>
      { authenticated && <LegacyPageTemplate html={html} /> }
      { !authenticated && "Loading..." }
    </Layout>
  )
}

export default LegacyPage;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        bodyId
        passwordProtected
      }
    }
  }`